import React from 'react'
import { navigate } from 'gatsby'
import { AuthWidget, AuthFormType } from '@ecommerce/shared'
import { Icon } from '../components/Icon/Icon'
import Layout from '../components/Layout/LayoutStaticPage'
import { BackButton } from '../components/Checkout/components/Layout'
import { Inner, Wrapper } from './cuenta-activada'
import { PgPageProps } from '../types/PgPages'
import withPageTransition from '../components/withPageTransition'
import { useUserLoginHandler } from '../hooks/useUserLoginHandler'

const LoginPage = ({
  pageContext: {
    currentCity: { slug },
  },
}: PgPageProps) => {
  const { UserLoginHandlerAlertComponent, userLoginCartHandler } = useUserLoginHandler()
  const onSignInFinished = () => navigate(`/${slug}?show_greeting=true`)
  return (
    <>
      <Layout title="Inicia sesión">
        <Wrapper>
          <Inner>
            <BackButton className="back-button" onClick={() => navigate(-1)}>
              <Icon iconId="arrow_left" />
              Volver
            </BackButton>
            <h1 className="login-title">Inicia sesión</h1>
            <AuthWidget
              showWidget={() => null}
              closeWidget={() => null}
              onClose={() => null}
              title="Inicia sesión"
              useModal={false}
              formSettings={{
                type: AuthFormType.SIGNIN,
                props: {
                  userLoginCartHandler,
                  onFinished: onSignInFinished,
                  showNoAccount: false,
                },
              }}
            />
          </Inner>
        </Wrapper>
      </Layout>
      <UserLoginHandlerAlertComponent onFinished={onSignInFinished} />
    </>
  )
}

export default withPageTransition(LoginPage)
